export const MAPBOX_URL =
  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={access_token}";
//"https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={access_token}";

export const MAPBOX_STREETS_V10_URL =
  "https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token={access_token}";

export const MAPBOX_ATTRIBUTION = `Map data &copy; 
<a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, 
<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>,
Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`;

export const baseLayers = [
  {
    name: "Streets",
    url: MAPBOX_STREETS_V10_URL,
    id: "mapbox/streets-v11",
  },
  {
    name: "Satellite",
    url: MAPBOX_URL,
    id: "mapbox/satellite-v9",
  },
  {
    name: "Satellite-Streets",
    url: MAPBOX_URL,
    id: "mapbox/satellite-streets-v11",
  },
  {
    name: "Light",
    url: MAPBOX_URL,
    id: "mapbox/light-v10",
  },
  {
    name: "Dark",
    url: MAPBOX_URL,
    id: "mapbox/dark-v10",
  },
];

export const dayTypeOptions = [
  { label: "All Daytypes", value: 10 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 },
];

export const yearsMonths = [
  { year: 2019, month: 3, yearMonth: "2019-March" },
  { year: 2019, month: 4, yearMonth: "2019-April" },
  { year: 2019, month: 5, yearMonth: "2019-May" },
  { year: 2019, month: 9, yearMonth: "2019-September" },
  { year: 2019, month: 10, yearMonth: "2019-October" },
  { year: 2019, month: 11, yearMonth: "2019-November" },
  { year: 2020, month: 2, yearMonth: "2020-February" },
  { year: 2020, month: 3, yearMonth: "2020-March" },
  { year: 2020, month: 4, yearMonth: "2020-April" },
  { year: 2020, month: 9, yearMonth: "2020-September" },
  { year: 2020, month: 10, yearMonth: "2020-October" },
  { year: 2020, month: 11, yearMonth: "2020-November" },
  
  { year: 2021, month: 2, yearMonth: "2021-February" },
  { year: 2021, month: 3, yearMonth: "2021-March" },
  { year: 2021, month: 4, yearMonth: "2021-April" },
  { year: 2021, month: 9, yearMonth: "2021-September" },
  { year: 2021, month: 10, yearMonth: "2021-October" },
  { year: 2021, month: 11, yearMonth: "2021-November" },
];

export const months = [
  { month: 1, description: "January" },
  { month: 2, description: "February" },
  { month: 3, description: "March" },
  { month: 4, description: "April" },
  { month: 5, description: "May" },
  { month: 6, description: "June" },
  { month: 7, description: "July" },
  { month: 8, description: "August" },
  { month: 9, description: "September" },
  { month: 10, description: "October" },
  { month: 11, description: "November" },
  { month: 12, description: "December" },
];

export const sitesYears = [2019, 2020, 2021];
export const sitesMonths = [
  { month: 2, description: "February" },
  { month: 3, description: "March" },
  { month: 4, description: "April" },
  { month: 5, description: "May" },
  { month: 6, description: "June" },
  { month: 7, description: "July" },
  { month: 8, description: "August" },
  { month: 9, description: "September" },
  { month: 10, description: "October" },
  { month: 11, description: "November" },
  { month: 12, description: "December" },
];

export const sitesYearsMonths = [
  { year: 2019, month: 3, yearMonth: "2019-March" },
  { year: 2019, month: 4, yearMonth: "2019-April" },
  { year: 2019, month: 5, yearMonth: "2019-May" },
  { year: 2019, month: 9, yearMonth: "2019-September" },
  { year: 2019, month: 10, yearMonth: "2019-October" },
  { year: 2019, month: 11, yearMonth: "2019-November" },
  { year: 2020, month: 2, yearMonth: "2020-February" },
  { year: 2020, month: 3, yearMonth: "2020-March" },
  { year: 2020, month: 4, yearMonth: "2020-April" },
  { year: 2020, month: 9, yearMonth: "2020-September" },
  { year: 2020, month: 10, yearMonth: "2020-October" },
  { year: 2020, month: 11, yearMonth: "2020-November" },
  { year: 2021, month: 2, yearMonth: "2021-February" },
  { year: 2021, month: 3, yearMonth: "2021-March" },
  { year: 2021, month: 4, yearMonth: "2021-April" },
  { year: 2021, month: 9, yearMonth: "2021-September" },
  { year: 2021, month: 10, yearMonth: "2021-October" },
  { year: 2021, month: 11, yearMonth: "2021-November" },
];

export const geographies = [
  {
    id: 1,
    name: "State",
    label: "State",
    lookup: "statewide",
    counties: [
      0, 1, 3, 5, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39,
      41, 43, 45, 47, 510,
    ],
  },
  {
    id: 2,
    name: "Western Region",
    label: "Western Region",
    lookup: "western",
    counties: [1, 23, 43],
    countiesLandUse: ["WASH", "ALLE", "GARR"],
  },
  {
    id: 3,
    name: "Central Region",
    label: "Central Region",
    lookup: "central",
    counties: [3, 2, 5, 510, 13, 25, 27],
    countiesLandUse: ["ANNE", "BACO", "BACI", "CARR", "HARF", "HOWA"],
  },
  {
    id: 4,
    name: "DC Metro Area",
    label: "DC Metro Area",
    lookup: "dc-metro-area",
    counties: [21, 31, 33, 0],
    countiesLandUse: ["FRED", "MONT", "PRIN"],
  },
  {
    id: 5,
    name: "Southern Region",
    label: "Southern Region",
    lookup: "southern",
    counties: [9, 17, 37],
    countiesLandUse: ["CALV", "CHAR", "STMA"],
  },
  {
    id: 6,
    name: "Eastern Shore Region",
    label: "Eastern Shore Region",
    lookup: "eastern-shore",
    counties: [11, 15, 19, 29, 35, 39, 41, 45, 47],
    countiesLandUse: [
      "CARO",
      "CECI",
      "DORC",
      "KENT",
      "QUEE",
      "SOME",
      "TALB",
      "WICO",
      "WORC",
    ],
  },
  {
    id: 7,
    name: "Baltimore Area",
    label: "Baltimore Area",
    lookup: "baltimore-area",
    counties: [5, 510],
    countiesLandUse: ["BACO", "BACI"],
  },
];

export const regionParkingDataTypes = [
  {
    value: 0,
    label: "All Parking",
  },
  {
    value: 1,
    label: "Parking < 1 hour",
  },
  {
    value: 2,
    label: "Parking >= 1 hour, < 3 hours",
  },
  {
    value: 3,
    label: "Parking >= 3 hours, < 7 hours",
  },
  {
    value: 4,
    label: "Parking >= 7 hours, < 11 hours",
  },
  {
    value: 5,
    label: "Parking >= 11 hours",
  },
];

export const parkingSitesOld = [
  { id: 1, name: "Abernathy", dir: ["N", "S"], type: "public" },
  { id: 2, name: "Bell County", dir: ["N", "S"], type: "public" },
  { id: 3, name: "Donley County", dir: ["E"], type: "public" },
  { id: 4, name: "Gray County", dir: ["W"], type: "public" },
  { id: 5, name: "Hill County", dir: ["N", "S"], type: "public" },
  { id: 6, name: "Hopkins County", dir: ["E", "W"], type: "public" },
  { id: 7, name: "LaSalle County", dir: ["N", "S"], type: "public" },
  { id: 8, name: "Medina County", dir: ["E", "W"], type: "public" },
  { id: 9, name: "Navarro County", dir: ["N", "S"], type: "public" },
  { id: 10, name: "Sarita", dir: ["N"], type: "public" },
  { id: 11, name: "Van Zandt County", dir: ["E", "W"], type: "public" },
  { id: 12, name: "Walker County", dir: ["N", "S"], type: "public" },
  { id: 13, name: "Ward County", dir: ["E", "W"], type: "public" },
  { id: 14, name: "FJS488 Cotulla", dir: ["S"], type: "private" },
  { id: 15, name: "FJS725 Baytown", dir: ["E"], type: "private" },
  { id: 16, name: "FJS726 Dallas", dir: ["W"], type: "private" },
  { id: 17, name: "FJS728 El Paso", dir: ["N"], type: "private" },
  { id: 18, name: "FJS736 Pecos", dir: ["W"], type: "private" },
  { id: 19, name: "FJS737 San Antonio", dir: ["S"], type: "private" },
  { id: 20, name: "FJS738 Tye", dir: ["W"], type: "private" },
  { id: 21, name: "PTCS1006 Junction", dir: ["N"], type: "private" },
  { id: 22, name: "PTCS1059 Schulenburg", dir: ["W"], type: "private" },
  { id: 23, name: "PTCS206 Weatherford", dir: ["W"], type: "private" },
  { id: 24, name: "PTCS209 Vanhorn", dir: ["E"], type: "private" },
];

export const vehicleWeightClassOptions = [
  { label: "All Vehicles (Weight Class 2 and 3)", value: 0 },
  { label: "Weight Class 2", value: 2 },
  { label: "Weight Class 3", value: 3 },
];

export const parkingDurationOptions = [
  {
    label: "All",
    value: "all",
    authorizedField: "total_auth",
    unauthorizedField: "total_unauth",
  },
  {
    label: "Less than 1 hour",
    value: "< 1",
    authorizedField: "total_auth1",
    unauthorizedField: "total_unauth1",
  },
  {
    label: "Greater than 1, less than 3 hours",
    value: "> 1, < 3",
    authorizedField: "total_auth1_3",
    unauthorizedField: "total_unauth1_3",
  },
  {
    label: "Greater than 3, less than 7 hours",
    value: "> 3, < 7",
    authorizedField: "total_auth3_7",
    unauthorizedField: "total_unauth3_7",
  },
  {
    label: "Greater than 7, less than 11 hours",
    value: "> 7, < 11",
    authorizedField: "total_auth7_11",
    unauthorizedField: "total_unauth7_11",
  },
  {
    label: "Greater than 11 hours",
    value: "> 11",
    authorizedField: "total_auth11",
    unauthorizedField: "total_unauth11",
  },
];

export const mpoList = [
  {mpoNumber: 166, mpoName: "Abilene"},
  {mpoNumber: 28, mpoName: "Alamo Area"},
  {mpoNumber: 120, mpoName: "Amarillo"},
  {mpoNumber: 249, mpoName: "Bryan-College Station"},
  {mpoNumber: 90, mpoName: "Capital Area"},
  {mpoNumber: 96, mpoName: "Corpus Christi"},
  {mpoNumber: 66, mpoName: "El Paso"},
  {mpoNumber: 232, mpoName: "Grayson County"},
  {mpoNumber: 15, mpoName: "Houston Galveston Area Council"},
  {mpoNumber: 277, mpoName: "Killeen-Temple"},
  {mpoNumber: 205, mpoName: "Laredo Webb County Area"},
  {mpoNumber: 361, mpoName: "Longview"},
  {mpoNumber: 122, mpoName: "Lubbock"},
  {mpoNumber: 282, mpoName: "North Central Texas Council of Governments"},
  {mpoNumber: 174, mpoName: "Permian Basin"},
  {mpoNumber: 230, mpoName: "Rio Grande Valley"},
  {mpoNumber: 208, mpoName: "San Angelo"},
  {mpoNumber: 135, mpoName: "South East Texas Regional Planning Commission"},
  {mpoNumber: 211, mpoName: "Texarkana"},
  {mpoNumber: 213, mpoName: "Tyler"},
  {mpoNumber: 363, mpoName: "Victoria"},
  {mpoNumber: 140, mpoName: "Waco"},
  {mpoNumber: 151, mpoName: "Wichita Falls"},
  ];
  

  export const parkingSites = [
    {id: 1, name: "El Paso County", dir: ["E","W",], type: "Public", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 2, name: "Fabens Speedway", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 3, name: "Culberson County", dir: ["E","W",], type: "Public", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 4, name: "Van Horn Loves Travel Stop", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 5, name: "Van Horn Pilot Travel Center Vanhorn Dr", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 6, name: "Chevron Van Horn", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 7, name: "Etholen Exxon", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 8, name: "El Paso Petro Travle Center Stockyard Dr", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 9, name: "El Paso Flying J Travel Center", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 10, name: "El Paso Loves Travel Stop", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 11, name: "El Paso Speedway I10", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 12, name: "El Paso Ta Express Travle Center Westway Blvd", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 13, name: "El Paso Ta Express Travel Center Kingsway Dr", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 14, name: "Anthony Pilot Travel Center", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 15, name: "Anthony Flying J Travel Center", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 16, name: "Anthony Loves Truck Stop", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 17, name: "Texas TIC Anthony", dir: ["S",], type: "Public", districtName: "El Paso", districtNumber: 24, mpoName: "El Paso", mpoNumber: 66},
    {id: 18, name: "Esperanza Traveling Tiger Center", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 19, name: "Etholen", dir: ["E","W",], type: "Public", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 20, name: "Plateau Truck Stop", dir: ["B",], type: "Private", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 21, name: "Fort Davis", dir: ["E","W",], type: "Public", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 22, name: "Saragosa Uncles Gas Station", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 23, name: "Pecos West County", dir: ["E","W",], type: "Public", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 24, name: "Toyah", dir: ["E","W",], type: "Public", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 25, name: "Pecos Sunoco Bickley", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 26, name: "Pecos Pilot Travel Center California Ave", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 27, name: "Pecos Stripes Lincoln St", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 28, name: "Pecos Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 29, name: "Pecos Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 30, name: "Orla Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 31, name: "Ward County", dir: ["E","W",], type: "Public", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 32, name: "Wickett Allsups I20", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 33, name: "Wickett Main Street Market 1219", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 34, name: "Thorntonville Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 35, name: "Thorntonville Kenk Kwik", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 36, name: "Kermit Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 37, name: "West Odessa Pilot Travel Center Meteor Crater Rd", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 38, name: "West Odessa Road Ranger", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 39, name: "Odessa Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 40, name: "West Odessa Stripes County Rd W", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 41, name: "Odessa Pilot Cardlock", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 42, name: "Odessa Flying J Travel Center I20", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 43, name: "West Odessa Stripes Kermit Hwy", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 44, name: "Midland Pilot Travel Center County Rd127", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 45, name: "Midland Loves Travel Center", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 46, name: "Midland Stripes Midkiff", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 47, name: "Midland Exxon Hwy20", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 48, name: "Midland Pilot", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 49, name: "Midland Sunoco Garden City Hwy", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 50, name: "Midland Stripes Travel Plaza Garden City Hwy", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 51, name: "Midland Flying J Travel Center 158", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 52, name: "Midland CS Plaza", dir: ["E","W",], type: "Public", districtName: "Odessa", districtNumber: 6, mpoName: "Permian Basin", mpoNumber: 174},
    {id: 53, name: "Crane Pilot Fuel Stop", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 54, name: "Fort Stockton Stripes Truck Stop 285", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 55, name: "Fort Stockton Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 56, name: "Fort Stockton Chevron I10", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 57, name: "Fort Stockton Loves Truck Care", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 58, name: "Fort Stockton Abandoned 77", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 59, name: "Fort Stockton", dir: ["E",], type: "Public", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 60, name: "Pecos East County", dir: ["N","S",], type: "Public", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 61, name: "Ozona", dir: ["E","W",], type: "Public", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 62, name: "Alpine", dir: ["N",], type: "Public", districtName: "El Paso", districtNumber: 24, mpoName: null, mpoNumber: null},
    {id: 63, name: "Sanderson Stripes", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 64, name: "Big Lake Pilot Travel Center", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 65, name: "Andrews Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 66, name: "Andres Strips Mustang Dr", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 67, name: "Andrews Pilot", dir: ["B",], type: "Private", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 68, name: "Andrews County", dir: ["N",], type: "Public", districtName: "Odessa", districtNumber: 6, mpoName: null, mpoNumber: null},
    {id: 69, name: "Seminole Harts Corner Travel Center", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 70, name: "Denver City Stripes Santa Fe", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 71, name: "Lamesa Yesway Seminole", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 72, name: "Brownfield Yesway Lanny Ave", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 73, name: "Ropesville Yesway", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 74, name: "Chisum Yesway Travel Center", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: "Lubbock", mpoNumber: 122},
    {id: 75, name: "Lubbock Yesway 46th St", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: "Lubbock", mpoNumber: 122},
    {id: 76, name: "Lubbock Phillips66 Avenue A", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: "Lubbock", mpoNumber: 122},
    {id: 77, name: "Lubbock Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: "Lubbock", mpoNumber: 122},
    {id: 78, name: "Aeg Petroleum US62", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: "Lubbock", mpoNumber: 122},
    {id: 79, name: "Lubbock Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: "Lubbock", mpoNumber: 122},
    {id: 80, name: "New Deal Travel Center", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 81, name: "Hale County", dir: ["N","S",], type: "Public", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 82, name: "Littleifeld Stripes 385", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 83, name: "Plainview Cefco Travel Center", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 84, name: "Tulia", dir: ["N","S",], type: "Public", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 85, name: "Tulia Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 86, name: "Canyon", dir: ["N","S",], type: "Public", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 87, name: "Amarillo Loves Travel Stop Hollywood Rd", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 88, name: "Amarillo Loves Travel Stop Arnot Rd", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 89, name: "Vega Pilot Travel Center I40", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 90, name: "Vega Travel Center", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 91, name: "Adrian Midway 66", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 92, name: "Amarillo Ta Travel Center Whitaker Rd", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 93, name: "Amarillo Loves Travel Stop Whitaker Rd", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 94, name: "Amarillo Petro Fuel Island", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 95, name: "Amarillo Pilot Travel Center Lakeside", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 96, name: "Amarillo Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 97, name: "Texas TIC Amarillo", dir: ["E",], type: "Public", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 98, name: "Amarillo National Truck Stop Amarillo", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: "Amarillo", mpoNumber: 120},
    {id: 99, name: "Amarillo Loves Travel Stop 207", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 100, name: "Claude Taylors Truck Stop", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 101, name: "Dumas Toot-n-Totum", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 102, name: "Dumas Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 103, name: "Dalhart Toot-n-Totum", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 104, name: "Stratford Allsups US287", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 105, name: "Stratford Pilot Travel Center Texas St", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 106, name: "Stratford Toot-n-Totum", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 107, name: "Texline Valero US87", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 108, name: "Friona Fast Stop Truck Wash", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 109, name: "Ralls Stripes", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 110, name: "Crosby County", dir: ["E",], type: "Public", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 111, name: "Post Allsups US84", dir: ["B",], type: "Private", districtName: "Lubbock", districtNumber: 5, mpoName: null, mpoNumber: null},
    {id: 112, name: "Bigspring", dir: ["E","W",], type: "Public", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 113, name: "Big Spring Stripes", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 114, name: "Big Spring Ta Travel Center 87", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 115, name: "Big Spring Pate Trucking", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 116, name: "Big Spring Pilot Travel Center 12th St", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 117, name: "Howard County", dir: ["E",], type: "Public", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 118, name: "Snyder Rip Griffin Travel Center", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 119, name: "M And M Fuels US84", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 120, name: "Mitchell County", dir: ["W",], type: "Public", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 121, name: "Colorado City Stripes", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 122, name: "Loraine", dir: ["E","W",], type: "Public", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 123, name: "Sterling City Stripes", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 124, name: "Sterling City Allsups US87", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 125, name: "Coke County", dir: ["S",], type: "Public", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 126, name: "San Angelo Stripes Ben Fricklin", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: "San Angelo", mpoNumber: 208},
    {id: 127, name: "San Angelo Stripes Bell", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: "San Angelo", mpoNumber: 208},
    {id: 128, name: "Paducah", dir: ["N",], type: "Public", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 129, name: "Childres Pilot Travel Center 287", dir: ["B",], type: "Private", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 130, name: "Childress Cefco Hwy287", dir: ["B",], type: "Private", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 131, name: "Estelline", dir: ["N",], type: "Public", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 132, name: "Memphis Loves Travel Center", dir: ["B",], type: "Private", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 133, name: "Donley County", dir: ["N","S",], type: "Public", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 134, name: "Donley County I40", dir: ["E",], type: "Public", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 135, name: "Gray County", dir: ["W",], type: "Public", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 136, name: "Perryton Kent Kwik", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 137, name: "Canadian Oasis Truck Stop", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 138, name: "Higgins Percs Truck Stop", dir: ["B",], type: "Private", districtName: "Amarillo", districtNumber: 4, mpoName: null, mpoNumber: null},
    {id: 139, name: "Guthrie", dir: ["N",], type: "Public", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 140, name: "Sylvester Empty Lot FM57", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 141, name: "Sweetwater Dirt Patch", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 142, name: "Sweet Water Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 143, name: "Sweetwater Ta Travel Center Rd143", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 144, name: "Nolan County", dir: ["E","W",], type: "Public", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 145, name: "Bronte Stripes", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 146, name: "Ozona Fuel America Travel Center", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 147, name: "Sutton County", dir: ["E","W",], type: "Public", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 148, name: "Sonora Jmc Transport", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 149, name: "Sonora Loves Travel Stop", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 150, name: "Fabrica Minits3", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 151, name: "Eagle Pass Minits Pilot Flying J", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 152, name: "Eagle Pass Stripes", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 153, name: "Sonora", dir: ["E","W",], type: "Public", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 154, name: "Collingsworth", dir: ["S",], type: "Public", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 155, name: "Shamrock Conoco", dir: ["B",], type: "Private", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 156, name: "Shamrock Cefco Travel Center", dir: ["B",], type: "Private", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 157, name: "Quanah Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 158, name: "Hardeman County", dir: ["E","W",], type: "Public", districtName: "Childress", districtNumber: 25, mpoName: null, mpoNumber: null},
    {id: 159, name: "Cernon Cefco Travel Center Bentley", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 160, name: "Stamford Yesway US277G", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 161, name: "Haskell County", dir: ["S",], type: "Public", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 162, name: "Merkel Allsups FM1235", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 163, name: "Tye Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: "Abilene", mpoNumber: 166},
    {id: 164, name: "Concho County", dir: ["E","W",], type: "Public", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 165, name: "Ballinger Stripes", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 166, name: "Seymour Allsups US183", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 167, name: "Harrold Southwest Convenience Store", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 168, name: "Tuscola Empty Lot 84", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 169, name: "Callahan County", dir: ["E","W",], type: "Public", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 170, name: "Baird Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: null, mpoNumber: null},
    {id: 171, name: "Junction", dir: ["N","S",], type: "Public", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 172, name: "Junction Pilot Travel Center", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 173, name: "Junction Timewise Food Store Travel Plaza", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 174, name: "Junction Genes Go Truck Stop", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 175, name: "Segovia 66 Truck Stop", dir: ["B",], type: "Private", districtName: "San Angelo", districtNumber: 7, mpoName: null, mpoNumber: null},
    {id: 176, name: "Brady Fuel Stop Brady Ts", dir: ["B",], type: "Private", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 177, name: "Brownwood Alon Hwy377", dir: ["B",], type: "Private", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 178, name: "Brownwood Stripes", dir: ["B",], type: "Private", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 179, name: "Crystal City Txb", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 180, name: "Carrizo Springs Valero", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 181, name: "Catarina One Stop", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 182, name: "La Salle County", dir: ["N","S",], type: "Public", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 183, name: "Encinal Road Ranger", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 184, name: "Encinal Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 185, name: "Texas TIC Laredo", dir: ["N",], type: "Public", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 186, name: "Laredo Western Express Wtcl", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: "Laredo Webb County Area", mpoNumber: 205},
    {id: 187, name: "Laredo Exxon Mines Rd", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: "Laredo Webb County Area", mpoNumber: 205},
    {id: 188, name: "Laredo Pilot Travel Center Unitec Industrial Park", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: "Laredo Webb County Area", mpoNumber: 205},
    {id: 189, name: "Laredo Flying J Travel Center Unitech Industrial Park", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: "Laredo Webb County Area", mpoNumber: 205},
    {id: 190, name: "Laredo Ta Travel Center Mercury Dr", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: "Laredo Webb County Area", mpoNumber: 205},
    {id: 191, name: "Freer Stripes", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 192, name: "Rio Bravo 7Eleven US83", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: "Laredo Webb County Area", mpoNumber: 205},
    {id: 193, name: "Cotulla Stripes", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 194, name: "Dilley Shell I35", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 195, name: "Pearsall Petro Travel Center", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 196, name: "Moore Road Ranger", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 197, name: "Amigos Country Corner", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 198, name: "Natalia Loves Travel Stop", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 199, name: "Medina County", dir: ["E","W","N","S",], type: "Public", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 200, name: "Von Ormy Pilot Travel Center", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 201, name: "Von Ormy Tex Best Travel Center", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 202, name: "Von Ormy Circlek Quintana", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 203, name: "Pleasanton Kuntry Korner", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 204, name: "Pleasanton Tex Best Travel Center", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 205, name: "San Antonio Quicktrip Labus Rd", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 206, name: "San Antonio Pilot Travel Center Eagle Frd", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 207, name: "San Antonio Tex Best Travel Center 1604", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 208, name: "San Antonio 7Eleven I37", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 209, name: "San Antonio Pilot Travel Center Ackerman Rd", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 210, name: "San Antonio Ta Travel Center Ackerman Rd", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 211, name: "San Antonio Ta Travel Center Foster Rd", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 212, name: "San Antonio Flying J Travel Center Foster Rd", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 213, name: "San Antonio Empty Lot I10", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 214, name: "San Antonio Circlek Oconnor", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 215, name: "Schertz Stripes", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 216, name: "Schertz Tiger Mart", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 217, name: "Seguin Main Street Market", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 218, name: "Seguin Loves Travel Stop", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 219, name: "Guadalupe County", dir: ["E","W",], type: "Public", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 220, name: "Luling Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 221, name: "New Braunfels Pilot Travel Center Algelt", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 222, name: "New Braunfels Tex Best Travel Center", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 223, name: "New Braunfels Ta Travel Center Conrads Ln", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 224, name: "San Marcos Truck Stop", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 225, name: "Kyle Tex Best Travel Center", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 226, name: "Welfare", dir: ["N","S",], type: "Public", districtName: "San Antonio", districtNumber: 15, mpoName: "Alamo Area", mpoNumber: 28},
    {id: 227, name: "Amigos Express", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 228, name: "El Tigre Food Store 17", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 229, name: "Rio Grande City Stripes", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 230, name: "Palmview Sunoco", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 231, name: "Mission Stripes", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 232, name: "Pharr River Mart", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 233, name: "Pharr Road Ranger", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 234, name: "Pharr Stripes Cage Blvd", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 235, name: "Pharr Stripes Polk Ave", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 236, name: "Donna Loves Truck Care", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 237, name: "Edinburg Stripes", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 238, name: "Edinburg Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 239, name: "Edinburg Ta Travel Center FM2812", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 240, name: "Edinburg Loves Travel Stop FM2812", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 241, name: "Combes Road Ranger", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 242, name: "Combes Stripes", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 243, name: "Harlingen Speedy Stop Ed Carey Dr", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 244, name: "San Benito Stripes", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 245, name: "Brownsville Stripes", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 246, name: "Los Indios Stripes 281", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: "Rio Grande Valley", mpoNumber: 230},
    {id: 247, name: "Kenedy County", dir: ["N","S",], type: "Public", districtName: "Pharr", districtNumber: 21, mpoName: null, mpoNumber: null},
    {id: 248, name: "Falfurrias Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: null, mpoNumber: null},
    {id: 249, name: "Falfurrias 7Eleven Sunoco", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: null, mpoNumber: null},
    {id: 250, name: "Falfurrias Stripes", dir: ["B",], type: "Private", districtName: "Pharr", districtNumber: 21, mpoName: null, mpoNumber: null},
    {id: 251, name: "Riviera Stripes", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 252, name: "Kingsville Circlek", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 253, name: "Kingsville Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 254, name: "Salice Tex Best", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 255, name: "Robstown Road Ranger", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 256, name: "Robstown Lonestar Travel Stop", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 257, name: "Corpus Christi Empty Lot Navigation Blvd", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: "Corpus Christi", mpoNumber: 96},
    {id: 258, name: "Odem Snappy Food", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 259, name: "Odem Circlek", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 260, name: "Sinton Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 261, name: "Sinton Truckees", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 262, name: "Mathis Criclek", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 263, name: "Woodsboro Fastbreak", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 264, name: "Refugio Stripes", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 265, name: "Refugio Travel Center", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 266, name: "River Creek Stripes Truck Stop", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 267, name: "George West Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 268, name: "River Creek Stripes", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 269, name: "George West Tex Best Travel Center", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 270, name: "Three Rivers Circlek", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 271, name: "Three Rivers Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 272, name: "Live Oak County", dir: ["N","S",], type: "Public", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 273, name: "Karnes City Stripes Truck Stop", dir: ["B",], type: "Private", districtName: "Corpus Christi", districtNumber: 16, mpoName: null, mpoNumber: null},
    {id: 274, name: "Pleasanton", dir: ["N","S",], type: "Public", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 275, name: "Victoria Speedy Stop Truck Stop", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: "Victoria", mpoNumber: 363},
    {id: 276, name: "Big Vic Truck Stop", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: "Victoria", mpoNumber: 363},
    {id: 277, name: "Victoria County", dir: ["N","S",], type: "Public", districtName: "Yoakum", districtNumber: 13, mpoName: "Victoria", mpoNumber: 363},
    {id: 278, name: "Edna Loves Travel Stop 59", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 279, name: "Ganado Ta Travel Center", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 280, name: "Cuero Stripes", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 281, name: "El Campo Western Store", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 282, name: "Hungerford Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 283, name: "Hungerford Foodmart", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 284, name: "Beasley Chevron", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 285, name: "Beasley Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 286, name: "Rosenberg Travel Plaza", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 287, name: "Rosenburg Valero Bryan Rd", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 288, name: "Bay City Stripes", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 289, name: "Sugar Valley Tc Country Store", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 290, name: "Angleton Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 291, name: "La Marque Shell", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 292, name: "Galveston Harborside Food Mart", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 293, name: "Houston Mchard Mart Post Oak", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 294, name: "Houston Texaco Fondren", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 295, name: "Houston Singh Mart Burdine St", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 296, name: "Houston Hobby Express Truck Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 297, name: "Laporte Lion King Truck Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 298, name: "La Porte One9 Travel Center Hwy146", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 299, name: "La Porte Moodys Travel Plaza", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 300, name: "Deer Park Loves Travel Stop Independence Pkwy", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 301, name: "Baytown Oasis Market 146", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 302, name: "Baytown Oasis Market 99", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 303, name: "Baytown Ta Express Oasis Travel Center FM1405", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 304, name: "Baytown North Main Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 305, name: "Mcnair Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 306, name: "Baytown Ta Travel Center Thompson Rd", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 307, name: "Baytown Express Travel Plaza", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 308, name: "Baytown Loves Travel Stop Thompson Rd", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 309, name: "Channellview Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 310, name: "Channelview Now And Forever", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 311, name: "Channelview Chevron Ave C", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 312, name: "Channelview Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 313, name: "Houston Normandy Truck Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 314, name: "Houston Stripes Federal Rd", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 315, name: "Alen Park Sunmart", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 316, name: "Houston Texas Truck Stop Laurentide St", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 317, name: "Houston Handy Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 318, name: "Pasadena Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 319, name: "Breaktime 225 Ts", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 320, name: "Houston Lockwood Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 321, name: "Houston A1 Fuel Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 322, name: "Houston Truckers Paradise Manitou", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 323, name: "Houston Loves Travel Stop Mccarty St", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 324, name: "Houston Mccarty Chevron 610", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 325, name: "Houston Pilot Travel Center Manitou", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 326, name: "Houston Xpress Truck Stop Mccarty St", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 327, name: "Houston Swingby Truck Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 328, name: "Houston Texas Travel Plaza Liberty Rd", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 329, name: "Houston Chevron Hempstead Rd", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 330, name: "Houston Loves Travel Stop Patton St", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 331, name: "Houston Potent Petroleum", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 332, name: "Royal Wood Express Truck Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 333, name: "Reservoir Acres Fuel Max", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 334, name: "Sheldon Stripes Blairwood Dr", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 335, name: "Sheldon Shell Crosby Fwy", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 336, name: "Crosby Maxx Crosby Dayton", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 337, name: "Dayton Fuel Max 1413", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 338, name: "Houston Chevron Aldine Bender", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 339, name: "Humble Eastex Truck Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 340, name: "Houston Ace Tire Shop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 341, name: "Humble Texas Travel Plaza I69", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 342, name: "Aldine Start Stop96", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 343, name: "Mont Belvieu Sunmart400", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 344, name: "Baytown Travel Plaza", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 345, name: "Cove I10 Travel Center", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 346, name: "Cove Time Maxx1", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 347, name: "Country Boys Country Store Travel Plaza", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 348, name: "Winnie Exxon 1663", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 349, name: "Winnie Jp Truck Stop", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 350, name: "Bingo Truck Stop", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 351, name: "Winnie Truck Stop 124", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "South East Texas Regional Planning Commission", mpoNumber: 135},
    {id: 352, name: "Chambers County", dir: ["E","W",], type: "Public", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 353, name: "Beaumont Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "South East Texas Regional Planning Commission", mpoNumber: 135},
    {id: 354, name: "Beaumont Ta Travel Center Walden Rd", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "South East Texas Regional Planning Commission", mpoNumber: 135},
    {id: 355, name: "Rose City Gateway Travel Plaza", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "South East Texas Regional Planning Commission", mpoNumber: 135},
    {id: 356, name: "Orange Pilot Travel Center 62", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "South East Texas Regional Planning Commission", mpoNumber: 135},
    {id: 357, name: "Orange Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "South East Texas Regional Planning Commission", mpoNumber: 135},
    {id: 358, name: "Texas TIC Orange", dir: ["W",], type: "Public", districtName: "Beaumont", districtNumber: 20, mpoName: "South East Texas Regional Planning Commission", mpoNumber: 135},
    {id: 359, name: "Buna Valero Corner Store", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: null, mpoNumber: null},
    {id: 360, name: "Jasper Valero Hall St", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: null, mpoNumber: null},
    {id: 361, name: "Houston 7Eleven Imperial Valley", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 362, name: "Houston Stripes Airtex", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 363, name: "Cypress Station Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 364, name: "Texaco Houston Gessner", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 365, name: "Houston Valero Little York Rd", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 366, name: "Houston Hempstead Truck Stop", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 367, name: "Houston Exxon Hammerly", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 368, name: "Katy Texaco I10", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 369, name: "Katy Loves Truck Care", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 370, name: "Katy Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 371, name: "Cypress Hwy 290 Ts", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 372, name: "Waller Loves Travel Stop FM2920", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 373, name: "Hempstead Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 374, name: "Hempstead Chevron", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 375, name: "Chappel Hill Circlek Gin", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 376, name: "Sealy Main Street Market", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 377, name: "Sealy Truck Stop", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 378, name: "Sealy Ta Travel Center Bernardo Rd", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 379, name: "Colorado County", dir: ["E","W",], type: "Public", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 380, name: "Weimar Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 381, name: "Weimar Truck Stop I10", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 382, name: "Schulenburg Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 383, name: "Hallettsville Mortons Truck Stop", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 384, name: "Flatonia Super Travel Center", dir: ["B",], type: "Private", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 385, name: "Flatonia", dir: ["E","W",], type: "Public", districtName: "Yoakum", districtNumber: 13, mpoName: null, mpoNumber: null},
    {id: 386, name: "Smithville Shell 71", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 387, name: "Giddings Cefco Travel Center", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: null, mpoNumber: null},
    {id: 388, name: "290 Travel Plaza Ts", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: null, mpoNumber: null},
    {id: 389, name: "Paige Fuel Max 2104", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 390, name: "Mustang Ridge Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 391, name: "Blanco Stripes", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: null, mpoNumber: null},
    {id: 392, name: "Comfort Loves Travel Stop", dir: ["B",], type: "Private", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 393, name: "Kerr County", dir: ["E","W",], type: "Public", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 394, name: "Kerrville", dir: ["S",], type: "Public", districtName: "San Antonio", districtNumber: 15, mpoName: null, mpoNumber: null},
    {id: 395, name: "Harper", dir: ["E","W",], type: "Public", districtName: "Austin", districtNumber: 14, mpoName: null, mpoNumber: null},
    {id: 396, name: "Bigs Valero", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: null, mpoNumber: null},
    {id: 397, name: "Hornsby Bend Valero", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 398, name: "Elgin Travel Center", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 399, name: "Elgin Texaco Hwy290", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 400, name: "Lexington Cefco", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: null, mpoNumber: null},
    {id: 401, name: "Caldwell Bigs Valero", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 402, name: "Pflugerville 7Eleven I35", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 403, name: "Round Rock Premier Truck Parking", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 404, name: "Liberty Hill Wag A Bag16", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 405, name: "Jarrell Circlek", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 406, name: "Jarrell Qmart Truck Stop And Travel Plaza", dir: ["B",], type: "Private", districtName: "Austin", districtNumber: 14, mpoName: "Capital Area", mpoNumber: 90},
    {id: 407, name: "Bell County", dir: ["N","S",], type: "Public", districtName: "Waco", districtNumber: 9, mpoName: "Killeen-Temple", mpoNumber: 277},
    {id: 408, name: "Salado Jds Travel Center", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Killeen-Temple", mpoNumber: 277},
    {id: 409, name: "Belton Cefco Travel Center", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Killeen-Temple", mpoNumber: 277},
    {id: 410, name: "Temple Cefco Travel Center Tx81", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Killeen-Temple", mpoNumber: 277},
    {id: 411, name: "Troy Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Killeen-Temple", mpoNumber: 277},
    {id: 412, name: "Hamilton Cefco Park Hill", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 413, name: "Hewitt Chevron Rolling Hills", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 414, name: "Hewitt Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 415, name: "Waco Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 416, name: "Riesel Exxon Memorial St", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 417, name: "Waco Sunoco 6", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 418, name: "Road Ranger", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 419, name: "Cowboy Truck Stop Sunoco", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 420, name: "Ross Gator Stop", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 421, name: "Woodlands Main Street Market", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 422, name: "Patton Village Circlek", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 423, name: "Patton Village Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 424, name: "Cleveland Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Beaumont", districtNumber: 20, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 425, name: "Cut And Shoot Kountry Mart", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 426, name: "Conroe Eaglemart 6", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 427, name: "Willis Loves Travel Stop Longstreet", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 428, name: "Willis Circlek", dir: ["B",], type: "Private", districtName: "Houston", districtNumber: 12, mpoName: "Houston-Galveston Area Council", mpoNumber: 15},
    {id: 429, name: "New Waverly Start Stop95", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 430, name: "Livingston Livingston Road Mart", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 431, name: "Polk County", dir: ["N","S",], type: "Public", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 432, name: "Bryan 7Eleven 190", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: "Bryan-College Station", mpoNumber: 249},
    {id: 433, name: "Big Gas Truck Stop", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: "Bryan-College Station", mpoNumber: 249},
    {id: 434, name: "Hearne Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 435, name: "Huntsville Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 436, name: "Huntsville Hitching Post Truck Terminal", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 437, name: "Arizona Jacks Truck Stop", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 438, name: "Walker County", dir: ["N","S",], type: "Public", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 439, name: "Leona Yellow Rose Travel Plaza", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 440, name: "Centerville", dir: ["N","S",], type: "Public", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 441, name: "Centerville Woodys Smokehouse", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 442, name: "Buffalo Truck Stop", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 443, name: "Buffalo Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 444, name: "Dew Lucky Js Travel Center", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 445, name: "Dew Jet Travel Plaza", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 446, name: "Fairfield I45 Truck Stop", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 447, name: "Fairfield Loves Travel Stop Commerce St", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
    {id: 448, name: "Palastine Gateway Travel Plaza", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 449, name: "Alto Stop N Shop", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 450, name: "Lufkin Pilot Travel Center Irving Dr", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 451, name: "Lufkin Loves Travel Stop 59", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 452, name: "Lufkin Lufkin Truck Stop", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 453, name: "Redland Loves Travel Center", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 454, name: "Nacogdoches Conoco", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 455, name: "Nacogdoches Ta Travel Center 59", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 456, name: "Nacogdoches Morgan Oil Travel Plaza", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 457, name: "Nacogdoches Brookshire Brothers", dir: ["B",], type: "Private", districtName: "Lufkin", districtNumber: 11, mpoName: null, mpoNumber: null},
    {id: 458, name: "Jacksonville 7Eleven US69", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 459, name: "Henderson Gateway Travel Plaza", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 460, name: "Carthage Ta Express Travel Center Hwy59", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 461, name: "Carthage Panola Pines Plaza", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 462, name: "B F Graves Ts", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 463, name: "Texas TIC Waskom", dir: ["W",], type: "Public", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 464, name: "Hallsville", dir: ["E",], type: "Public", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 465, name: "Longview Tex Best", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Longview", mpoNumber: 361},
    {id: 466, name: "Kilgore Ta Travel Center", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 467, name: "Winona", dir: ["E","W",], type: "Public", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 468, name: "Tyler Texas Best", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Tyler", mpoNumber: 213},
    {id: 469, name: "Tyler Triple J", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Tyler", mpoNumber: 213},
    {id: 470, name: "Tyler Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Tyler", mpoNumber: 213},
    {id: 471, name: "Lindale Quicktrip Hwy69", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Tyler", mpoNumber: 213},
    {id: 472, name: "Tyler Travel Center", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Tyler", mpoNumber: 213},
    {id: 473, name: "Tyler Truck Express", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Tyler", mpoNumber: 213},
    {id: 474, name: "Tyler Travel Stop Loop323", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Tyler", mpoNumber: 213},
    {id: 475, name: "Hideaway Oasis Super Stop", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: "Tyler", mpoNumber: 213},
    {id: 476, name: "Van Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 477, name: "Van Loves Travel Center FM314", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 478, name: "Canton Pilot Travel Center I20", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 479, name: "Dukes Travel Plaza Of Canton 64 Ts", dir: ["B",], type: "Private", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 480, name: "Navarro County", dir: ["S",], type: "Public", districtName: "Dallas", districtNumber: 18, mpoName: null, mpoNumber: null},
    {id: 481, name: "Dawson Shell Hwy31", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: null, mpoNumber: null},
    {id: 482, name: "Rice Exxon", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: null, mpoNumber: null},
    {id: 483, name: "Alma Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 484, name: "Ennis Tiger Mart", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 485, name: "Palmer Sunmart Travel Center", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 486, name: "Italy Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 487, name: "Italy Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 488, name: "Petro Travel Center", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 489, name: "Hillsboro Ta Travel Center Hwy77", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 490, name: "Hillsboro Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 491, name: "Hill County", dir: ["N","S",], type: "Public", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 492, name: "Abbott Sunmart", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 493, name: "Itasca Exxon", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 494, name: "Covington Cefco", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 495, name: "Waxahachie One9 Fuel Network", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 496, name: "Waxahachie Qwik Stop I35E", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 497, name: "Waxahachie Quicktrip Butcher Rd", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 498, name: "Midlothian Smart Stops Texas1", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 499, name: "Midlothian Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 500, name: "Midlothian Mini Mart", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 501, name: "Red Oak Travel Center", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 502, name: "Alvarado Shell I35", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 503, name: "Alvarado Sunnys Market", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 504, name: "Wilmer One9 Dealer Ts", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 505, name: "Hutchins Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 506, name: "Hutchins Quicktrip Wintergreen Rd", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 507, name: "Hutchins Top Fuel I45", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 508, name: "Dallas Ta Travel Center Bonnie View", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 509, name: "Dallas Flying J Travel Center Bonnie View Rd", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 510, name: "Dallas Circlek 342", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 511, name: "Dallas Pilot Travel Center Cedardale", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 512, name: "Dallas Loves Travel Stop Polk St", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 513, name: "Dallas Marlows Fuel Center", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 514, name: "Seagoville Circlek", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 515, name: "Forney Xpress Travel Center", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 516, name: "Dallas Fuel City Express Ts Town East Blvd", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 517, name: "Dallas Fuel City Mesquite Ts", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 518, name: "Garand One9 Fuel Network", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 519, name: "Dallas Knox Fuel", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 520, name: "Dallas Quick Trip Davis St", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 521, name: "Big D Travel Center", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 522, name: "Grand Prarie Mr Fuel", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 523, name: "Fort Worth Loves Travel Stop Garden Acres", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 524, name: "Fort Worth Qt Travel Stop Everman Pkwy", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 525, name: "Godley Cefco", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 526, name: "Tolar Meyers Qwik Stop", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 527, name: "Fort Worth Texaco Truck Stop Benbrook", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 528, name: "Big Horn Travel Center", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 529, name: "365 Travel Center Ts", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 530, name: "Fort Worth Alliance Gateway", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 531, name: "Haslet Texas Travel Plaza", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 532, name: "Haslet Tiger Mart", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 533, name: "Elizabethtown Quicktrip", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 534, name: "Dallas Whip In Holfords Prarie", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 535, name: "Rockwall Ta Travel Center", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 536, name: "Rockwall Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 537, name: "Royse City Tiger Mart", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 538, name: "Royse City Quick Trac09", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 539, name: "Caddo Mills Mega Fuel Stop", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 540, name: "Caddo Mills Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 541, name: "Greenville Quicktrip Hwy67", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 542, name: "Greenville Circlek Moulton", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 543, name: "Hopkins County", dir: ["E","W",], type: "Public", districtName: "Paris", districtNumber: 1, mpoName: null, mpoNumber: null},
    {id: 544, name: "Suplhur Springs Pilot Travel Center", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: null, mpoNumber: null},
    {id: 545, name: "Mt Vernon Cefco Travel Center I30", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: null, mpoNumber: null},
    {id: 546, name: "Vernon Loves Travel Stop Holbrook", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: null, mpoNumber: null},
    {id: 547, name: "Winfield Charge Up", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 548, name: "Omaha Empty Lot Hwy67", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 549, name: "Bettie Midway Armadillo Truck Stop", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 550, name: "Gilmer Cefco Travel Center", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 551, name: "Linden Linden Fuel Center", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 552, name: "Punj Truck Stop And Indian", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 553, name: "Domino Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 554, name: "Texarkana Travel Stop Wallace Dr", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: "Texarkana", mpoNumber: 211},
    {id: 555, name: "Texas TIC Texark", dir: ["W",], type: "Public", districtName: "Atlanta", districtNumber: 19, mpoName: "Texarkana", mpoNumber: 211},
    {id: 556, name: "Hooks Loves Truck Stop", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 557, name: "Hooks Sunshine Travel Plaza", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 558, name: "New Boston Circlek", dir: ["B",], type: "Private", districtName: "Atlanta", districtNumber: 19, mpoName: null, mpoNumber: null},
    {id: 559, name: "Paris Shell Main St", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: null, mpoNumber: null},
    {id: 560, name: "Paris Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: null, mpoNumber: null},
    {id: 561, name: "Trenton Tex Best Travel Center", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: null, mpoNumber: null},
    {id: 562, name: "Anna Loves Travel Stop White St", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 563, name: "Anna Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 564, name: "Texas TIC Denison", dir: ["N",], type: "Public", districtName: "Paris", districtNumber: 1, mpoName: "Grayson County", mpoNumber: 232},
    {id: 565, name: "Whitesboro Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Paris", districtNumber: 1, mpoName: "Grayson County", mpoNumber: 232},
    {id: 566, name: "Gainesville", dir: ["S",], type: "Public", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 567, name: "Gainesville Road Ranger", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 568, name: "Gainesville Quicktrip Summit Ave", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 569, name: "Valleyview", dir: ["N","S",], type: "Public", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 570, name: "Valley View One9 Fuel Network", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 571, name: "Drop Tine Services", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 572, name: "Denton Loves Travel Stop Barthold Rd", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 573, name: "Denton Ta Travel Center Purple Heart Trl", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 574, name: "Springtown Qwik Stop", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 575, name: "Rhome Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 576, name: "Big Z Travel Center", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 577, name: "New Fairview Pilot Travel Center Hwy287", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 578, name: "Decatur Shell US287", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 579, name: "Decatur Alon US87", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 580, name: "Decatur Truck And Auto Plaza Ts", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 581, name: "Denton Jj Truck Stop", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 582, name: "Denton Martin Eagle Oil", dir: ["B",], type: "Private", districtName: "Dallas", districtNumber: 18, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 583, name: "Bridgeport Loves Travel Stop", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 584, name: "Bridgeport Quick Trac 101", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 585, name: "Wise County", dir: ["N",], type: "Public", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 586, name: "Alvord Paradise Truck Park", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 587, name: "Alvord Trucker Parking Paradise Quick Stop", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 588, name: "Sunset", dir: ["N","S",], type: "Public", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 589, name: "Bowie Star Travel Center", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 590, name: "Henrietta", dir: ["N",], type: "Public", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 591, name: "Wichita Falls Loves Travel Stop Windthorst", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: "Wichita Falls", mpoNumber: 151},
    {id: 592, name: "Wichita Falls Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: "Wichita Falls", mpoNumber: 151},
    {id: 593, name: "Iowa Park Cefco Travel Center", dir: ["B",], type: "Private", districtName: "Wichita Falls", districtNumber: 3, mpoName: "Wichita Falls", mpoNumber: 151},
    {id: 594, name: "Wichita County", dir: ["E","W",], type: "Public", districtName: "Wichita Falls", districtNumber: 3, mpoName: null, mpoNumber: null},
    {id: 595, name: "Cisco Flying J Dealer Cisco Travel Plaza", dir: ["B",], type: "Private", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 596, name: "Cisco Stripes 206", dir: ["B",], type: "Private", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 597, name: "Cowpokes", dir: ["B",], type: "Private", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 598, name: "Eastland Texaco I20", dir: ["B",], type: "Private", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 599, name: "Eastland County", dir: ["E","W",], type: "Public", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 600, name: "Strawn", dir: ["E","W",], type: "Public", districtName: "Brownwood", districtNumber: 23, mpoName: null, mpoNumber: null},
    {id: 601, name: "Gordon Texaco I20", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: null, mpoNumber: null},
    {id: 602, name: "Weatherford Pilot Travel Center Old Dennis", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 603, name: "Weatherford Quick Trip Bowie", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 604, name: "406 Chrome Shop Truck And Travel Center", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 605, name: "Weatherford Petro Travel Center", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 606, name: "Weatherford Loves Truck Stop", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 607, name: "Van Zandt County", dir: ["E","W",], type: "Public", districtName: "Tyler", districtNumber: 10, mpoName: null, mpoNumber: null},
    {id: 608, name: "Hamilton Cefco Ross", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 609, name: "Gatesville Priutts", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 610, name: "Waco Cefco Bagby", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 611, name: "Waco Cefco Imperial", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 612, name: "Bellmead Cefco", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 613, name: "Riesel Cefco", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 614, name: "Kosse Exxon", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 615, name: "Prairie Hill Sunmart", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 616, name: "97.0880518Â°W 31.8979715Â°N", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 617, name: "Abbott Travel Center Ts", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 618, name: "Hillsboro El Taco Jalisco", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: null, mpoNumber: null},
    {id: 619, name: "Waco Quicktrip New Rd", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 620, name: "Hewitt Circlek", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Waco", mpoNumber: 140},
    {id: 621, name: "Temple Texstar Travel Center I35", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Killeen-Temple", mpoNumber: 277},
    {id: 622, name: "Jarrell Texas Travel Center", dir: ["B",], type: "Private", districtName: "Waco", districtNumber: 9, mpoName: "Killeen-Temple", mpoNumber: 277},
    {id: 623, name: "Tye Yesway", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: "Abilene", mpoNumber: 166},
    {id: 624, name: "Tye Wes T Go Travel Center", dir: ["B",], type: "Private", districtName: "Abilene", districtNumber: 8, mpoName: "Abilene", mpoNumber: 166},
    {id: 625, name: "Cotulla Flying J Travel Center", dir: ["B",], type: "Private", districtName: "Laredo", districtNumber: 22, mpoName: null, mpoNumber: null},
    {id: 626, name: "Fort Worth Drivers Truck Wash", dir: ["B",], type: "Private", districtName: "Fort Worth", districtNumber: 2, mpoName: "North Central Texas Council of Governments", mpoNumber: 282},
    {id: 627, name: "Huntsville Kudos Travel Center And Truck Stop", dir: ["B",], type: "Private", districtName: "Bryan", districtNumber: 17, mpoName: null, mpoNumber: null},
  ];

export const txdotDistricts = [
  {
    districtNumber: 8,
    districtName: "Abilene",
  },
  {
    districtNumber: 4,
    districtName: "Amarillo",
  },
  {
    districtNumber: 19,
    districtName: "Atlanta",
  },
  {
    districtNumber: 14,
    districtName: "Austin",
  },
  {
    districtNumber: 20,
    districtName: "Beaumont",
  },
  {
    districtNumber: 23,
    districtName: "Brownwood",
  },
  {
    districtNumber: 17,
    districtName: "Bryan",
  },
  {
    districtNumber: 25,
    districtName: "Childress",
  },
  {
    districtNumber: 16,
    districtName: "Corpus Christi",
  },
  {
    districtNumber: 18,
    districtName: "Dallas",
  },
  {
    districtNumber: 24,
    districtName: "El Paso",
  },
  {
    districtNumber: 2,
    districtName: "Fort Worth",
  },
  {
    districtNumber: 12,
    districtName: "Houston",
  },
  {
    districtNumber: 22,
    districtName: "Laredo",
  },
  {
    districtNumber: 5,
    districtName: "Lubbock",
  },
  {
    districtNumber: 11,
    districtName: "Lufkin",
  },
  {
    districtNumber: 6,
    districtName: "Odessa",
  },
  {
    districtNumber: 1,
    districtName: "Paris",
   },
  {
    districtNumber: 21,
    districtName: "Pharr",
  },
  {
    districtNumber: 7,
    districtName: "San Angelo",
  },
  {
    districtNumber: 15,
    districtName: "San Antonio",
  },
  {
    districtNumber: 10,
    districtName: "Tyler",
  },
  {
    districtNumber: 9,
    districtName: "Waco",
  },
  {
    districtNumber: 3,
    districtName: "Wichita Falls",
  },
  {
    districtNumber: 13,
    districtName: "Yoakum",
  },
];